html {
  min-height: 100%;
}

body {
  color: #888ea8;
  height: 100%;
  font-size: 0.875rem;
  background: #f1f2f3;
  overflow-x: hidden;
  overflow-y: auto;
  letter-spacing: 0.0312rem;
  font-family: 'Nunito', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: #3b3f5c;
}

:focus {
  outline: none;
}

::-moz-selection {
  /* Code for Firefox */
  color: #1b55e2;
  background: transparent;
}

::selection {
  color: #1b55e2;
  background: transparent;
}

p {
  margin-top: 0;
  margin-bottom: 0.625rem;
  color: #515365;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #f1f2f3;
}

strong {
  font-weight: 600;
}

code {
  color: #e7515a;
}

select.form-custom::-ms-expand {
  display: none;
}

.custom-file-input:focus ~ .custom-file-label {
  border: 1px solid #ced4da;
  box-shadow: none;

  &::after {
    border: none;
    border-left: 1px solid #ced4da;
  }
}

.lead a.btn.btn-primary.btn-lg {
  margin-top: 15px;
  border-radius: 4px;
}

.jumbotron {
  background-color: #f1f2f3;
}

.mark, mark {
  background-color: #ffeccb;
}

.code-section-container {
  margin-top: 20px;
  text-align: left;
}

.toggle-code-snippet {
  border: none;
  background-color: transparent !important;
  padding: 0px !important;
  box-shadow: none !important;
  color: #888ea8 !important;
  margin-bottom: -24px;
  border-bottom: 1px dashed #bfc9d4;
  border-radius: 0;

  svg {
    color: #1b55e2;
  }
}

.code-section {
  padding: 0;
  height: 0;
}

.code-section-container.show-code .code-section {
  margin-top: 20px;
  height: auto;
}

.code-section pre {
  margin-bottom: 0;
  height: 0;
  padding: 0;
  border-radius: 6px;
}

.code-section-container.show-code .code-section pre {
  height: auto;
  padding: 22px;
}

.code-section code {
  color: #fff;
}

/*blockquote*/

blockquote {
  &.blockquote {
    color: #0e1726;
    padding: 20px 20px 20px 14px;
    font-size: 0.875rem;
    background-color: #ffffff;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #e0e6ed;
    border-left: 2px solid #1b55e2;
    -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);

    > p {
      margin-bottom: 0;
    }
  }

  .small:before, footer:before, small:before {
    content: '\2014 \00A0';
  }

  .small, footer, small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777;
  }

  &.media-object {
    &.m-o-border-right {
      border-right: 4px solid #1b55e2;
      border-left: none;
    }

    .media .usr-img img {
      width: 55px;
    }
  }
}

/* Icon List */

.list-icon {
  list-style: none;
  padding: 0;
  margin-bottom: 0;

  li:not(:last-child) {
    margin-bottom: 15px;
  }

  svg {
    width: 18px;
    height: 18px;
    color: #1b55e2;
    margin-right: 2px;
    vertical-align: sub;
  }

  .list-text {
    font-size: 14px;
    font-weight: 600;
    color: #515365;
    letter-spacing: 1px;
  }
}

a {
  color: #515365;
  outline: none;

  &:hover {
    color: #555555;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    text-decoration: none;
  }
}

button:focus {
  outline: none;
}

textarea {
  outline: none;

  &:focus {
    outline: none;
  }
}

.btn-link:hover {
  text-decoration: none;
}

span {
  &.blue {
    color: #1b55e2;
  }

  &.green {
    color: #8dbf42;
  }

  &.red {
    color: #e7515a;
  }
}

/*      Form Group Label       */

.form-group label, label {
  font-size: 15px;
  color: #acb0c3;
  letter-spacing: 1px;
}

/*  Disable forms     */

.custom-control-input:disabled ~ .custom-control-label {
  color: #d3d3d3;
}

/*      Form Control       */

.form-control {
  height: auto;
  border: 1px solid #bfc9d4;
  color: #3b3f5c;
  font-size: 15px;
  padding: 8px 10px;
  letter-spacing: 1px;
  height: calc(1.4em + 1.4rem + 2px);
  padding: .75rem 1.25rem;
  border-radius: 6px;

  &:focus {
    box-shadow: 0 0 5px 2px rgba(194, 213, 255, 0.6196078431);
    border-color: #1b55e2;
    color: #3b3f5c;
  }

  &::-webkit-input-placeholder, &::-ms-input-placeholder, &::-moz-placeholder {
    color: #acb0c3;
    font-size: 15px;
  }

  &:focus {
    &::-webkit-input-placeholder, &::-ms-input-placeholder, &::-moz-placeholder {
      color: #d3d3d3;
      font-size: 15px;
    }
  }

  &.form-control-lg {
    font-size: 19px;
    padding: 11px 20px;
  }

  &.form-control-sm {
    padding: 7px 16px;
    font-size: 13px;
  }
}

/*      Custom Select       */

.custom-select {
  height: auto;
  border: 1px solid #f1f2f3;
  color: #3b3f5c;
  font-size: 15px;
  padding: 8px 10px;
  letter-spacing: 1px;
  background-color: #f1f2f3;

  &.custom-select-lg {
    font-size: 18px;
    padding: 16px 20px;
  }

  &.custom-select-sm {
    font-size: 13px;
    padding: 7px 16px;
  }

  &:focus {
    box-shadow: none;
    border-color: #1b55e2;
    color: #3b3f5c;
  }
}

/*      Form Control File       */

.form-control-file {
  width: 100%;
  color: #5c1ac3;

  &::-webkit-file-upload-button {
    letter-spacing: 1px;
    padding: 9px 20px;
    text-shadow: none;
    font-size: 12px;
    color: #fff;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    transition: .2s ease-out;
    touch-action: manipulation;
    cursor: pointer;
    background-color: #5c1ac3;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    border-radius: 4px;
    border: transparent;
    outline: none;
  }

  &::-ms-file-upload-button {
    letter-spacing: 1px;
    padding: 9px 20px;
    text-shadow: none;
    font-size: 14px;
    color: #fff;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    transition: .2s ease-out;
    touch-action: manipulation;
    cursor: pointer;
    background-color: #5c1ac3;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    border-radius: 4px;
    border: transparent;
    outline: none;
  }

  &.form-control-file-rounded::-webkit-file-upload-button {
    -webkit-border-radius: 1.875rem !important;
    -moz-border-radius: 1.875rem !important;
    -ms-border-radius: 1.875rem !important;
    -o-border-radius: 1.875rem !important;
    border-radius: 1.875rem !important;
  }
}

select.form-control.form-custom {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  vertical-align: middle;
  background: #fff url(../img/arrow-down.png) no-repeat right 0.75rem center;
  background-size: 13px 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*      Form Control Custom File       */

.custom-file {
  height: auto;

  input {
    height: auto;
  }
}

.custom-file-label {
  height: auto;
  border: 1px solid #f1f2f3;
  color: #3b3f5c;
  font-size: 15px;
  padding: 8px 10px;
  letter-spacing: 1px;
  background-color: #f1f2f3;

  &::after {
    height: auto;
    padding: 8px 12px;
    color: #515365;
  }
}

/*      Input Group      */

.input-group {
  button:hover, .btn:hover, button:focus, .btn:focus {
    transform: none;
  }

  .input-group-prepend .input-group-text {
    border: 1px solid #bfc9d4;
    background-color: #f1f2f3;

    svg {
      color: #888ea8;
    }
  }

  &:hover .input-group-prepend .input-group-text svg {
    color: #1b55e2;
    fill: rgba(27, 85, 226, 0.2392156863);
  }

  .input-group-append .input-group-text {
    border: 1px solid #bfc9d4;
    background-color: #f1f2f3;

    svg {
      color: #888ea8;
    }
  }

  &:hover .input-group-append .input-group-text svg {
    color: #1b55e2;
    fill: rgba(27, 85, 226, 0.2392156863);
  }
}

/*      Input Group append       */

/*      Input Group Append       */

/*      Validation Customization      */

.invalid-feedback {
  color: #e7515a;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
}

.valid-feedback {
  color: #8dbf42;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
}

.valid-tooltip {
  background-color: #8dbf42;
}

.invalid-tooltip {
  background-color: #e7515a;
}

.custom-select.is-valid, .form-control.is-valid {
  border-color: #8dbf42;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%238dbf42' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
}

.was-validated {
  .custom-select:valid, .form-control:valid {
    border-color: #8dbf42;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%238dbf42' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
  }
}

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #8dbf42;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23e7515a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
}

.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #e7515a;
}

.dropdown-toggle:after, .dropup .dropdown-toggle::after, .dropright .dropdown-toggle::after, .dropleft .dropdown-toggle::before {
  display: none;
}

.dropdown-toggle svg.feather[class*="feather-chevron-"] {
  width: 15px;
  height: 15px;
  vertical-align: middle;
}

.btn {
  padding: 0.4375rem 1.25rem;
  text-shadow: none;
  font-size: 14px;
  color: #3b3f5c;
  font-weight: normal;
  white-space: normal;
  word-wrap: break-word;
  transition: .2s ease-out;
  touch-action: manipulation;
  cursor: pointer;
  background-color: #f1f2f3;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
  will-change: opacity, transform;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;

  svg {
    width: 20px;
    height: 20px;
    vertical-align: bottom;
  }

  &.rounded-circle {
    height: 40px;
    width: 40px;
    padding: 8px 8px;
  }

  &:hover {
    color: #3b3f5c;
    background-color: #f1f2f3;
    border-color: #d3d3d3;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 #e0e6ed,0 2px 10px 0 #e0e6ed;
    opacity: 0.7;
  }
}

.btn-group .btn {
  &:hover {
    opacity: 1;
  }
}

.btn {
  &.disabled, &.btn[disabled] {
    background-color: #f1f2f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.13);
  }

  &.disabled:hover, &.btn[disabled]:hover {
    cursor: not-allowed;
  }

  .caret {
    border-top-color: #0e1726;
    margin-top: 0;
    margin-left: 3px;
    vertical-align: middle;
  }

  + {
    .caret, .dropdown-toggle .caret {
      margin-left: 0;
    }
  }
}

.btn-group {
  > .btn, .btn {
    padding: 8px 14px;
  }
}

.btn-group-lg {
  > .btn, .btn {
    font-size: 1.125rem;
  }

  > .btn {
    padding: .625rem 1.5rem;
    font-size: 16px;
  }
}

.btn-lg {
  padding: .625rem 1.5rem;
  font-size: 16px;
}

.btn-group {
  > .btn.btn-lg, .btn.btn-lg {
    padding: .625rem 1.5rem;
    font-size: 16px;
  }
}

.btn-group-lg {
  > .btn, .btn {
    font-size: 1.125rem;
  }
}

.btn-group-sm > .btn, .btn-sm {
  font-size: 0.6875rem;
}

.btn-group {
  > .btn.btn-sm, .btn.btn-sm {
    font-size: 0.6875rem;
  }

  .dropdown-menu {
    border: 1px solid #e0e6ed;
    box-shadow: rgba(113, 106, 202, 0.2) 0px 0px 15px 1px;
    padding: 10px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;

    a.dropdown-item {
      border-radius: 5px;
      font-size: 12px;
      font-weight: 700;
      color: #888ea8;
      padding: 11px 8px;

      &:hover {
        background-color: #f1f2f3;
      }

      svg {
        cursor: pointer;
        color: #888ea8;
        margin-right: 6px;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        fill: rgba(0, 23, 55, 0.08);
      }

      &:hover svg {
        color: #1b55e2;
      }
    }
  }
}

.dropdown:not(.custom-dropdown-icon) .dropdown-menu {
  border: none;
  border: 1px solid #e0e6ed;
  z-index: 899;
  box-shadow: rgba(113, 106, 202, 0.2) 0px 0px 15px 1px;
  padding: 10px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;

  a.dropdown-item {
    font-size: 12px;
    font-weight: 700;
    color: #888ea8;
    padding: 11px 8px;

    &.active, &:active {
      background-color: transparent;
      color: #3b3f5c;
      font-weight: 700;
    }

    &:hover {
      color: #888ea8;
      background-color: #f1f2f3;
    }
  }
}

.btn-primary:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-success:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-info:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-danger:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-warning:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-dark:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-success:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-info:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-danger:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-warning:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-secondary:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-dark:not(:disabled):not(.disabled) {
  &.active:focus, &:active:focus {
    box-shadow: none;
  }
}

.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn {
  &.focus, &:focus {
    box-shadow: none;
  }
}

.btn-success:focus, .btn-info:focus, .btn-danger:focus, .btn-warning:focus, .btn-secondary:focus, .btn-dark:focus, .btn-outline-success:focus, .btn-outline-info:focus, .btn-outline-danger:focus, .btn-outline-warning:focus, .btn-outline-secondary:focus, .btn-outline-dark:focus .btn-light-default:focus, .btn-light-primary:focus, .btn-light-success:focus, .btn-light-info:focus, .btn-light-danger:focus, .btn-light-warning:focus, .btn-light-secondary:focus, .btn-light-dark:focus {
  box-shadow: none;
}

/*      Default Buttons       */

.btn-primary {
  color: #fff !important;
  background-color: #1b55e2 !important;
  border-color: #1b55e2;

  &:hover, &:focus {
    color: #fff !important;
    background-color: #1b55e2;
    box-shadow: none;
    border-color: #1b55e2;
  }

  &:active, &.active {
    background-color: #1b55e2;
    border-top: 1px solid #1b55e2;
  }

  &.disabled, &.btn[disabled], &:disabled {
    background-color: #1b55e2;
    border-color: #1b55e2;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  &.active {
    &.focus, &:focus, &:hover {
      color: #fff !important;
      background-color: #2aebcb;
      border-color: #2aebcb;
    }
  }

  &.focus:active {
    color: #fff !important;
    background-color: #2aebcb;
    border-color: #2aebcb;
  }

  &:active {
    &:focus, &:hover {
      color: #fff !important;
      background-color: #2aebcb;
      border-color: #2aebcb;
    }
  }
}

.open > .dropdown-toggle.btn-primary {
  &.focus, &:focus, &:hover {
    color: #fff !important;
    background-color: #2aebcb;
    border-color: #2aebcb;
  }
}

.btn-primary:not(:disabled):not(.disabled) {
  &.active, &:active {
    color: #fff !important;
    background-color: #1b55e2;
    border-color: #1b55e2;
  }
}

.show > .btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #1b55e2;
  border-color: #1b55e2;
}

.btn-primary .caret {
  border-top-color: #fff;
}

.btn-group.open .btn-primary.dropdown-toggle {
  background-color: #c2d5ff;
}

.btn-secondary {
  color: #fff !important;
  background-color: #5c1ac3;
  border-color: #5c1ac3;

  &:hover, &:focus {
    color: #fff !important;
    background-color: #5c1ac3;
    box-shadow: none;
    border-color: #5c1ac3;
  }

  &:active, &.active {
    background-color: #5c1ac3;
    border-top: 1px solid #5c1ac3;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      color: #fff !important;
      background-color: #5c1ac3;
      border-color: #5c1ac3;
    }
  }
}

.show > .btn-secondary.dropdown-toggle {
  color: #fff !important;
  background-color: #5c1ac3;
  border-color: #5c1ac3;
}

.btn-secondary {
  &.disabled, &.btn[disabled], &:disabled {
    background-color: #5c1ac3;
    border-color: #5c1ac3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .caret {
    border-top-color: #fff;
  }
}

.btn-info {
  color: #fff !important;
  background-color: #2196f3;
  border-color: #2196f3;

  &:hover, &:focus {
    color: #fff !important;
    background-color: #2196f3;
    box-shadow: none;
    border-color: #2196f3;
  }

  &:active, &.active {
    background-color: #2196f3;
    border-top: 1px solid #2196f3;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      color: #fff !important;
      background-color: #2196f3;
      border-color: #2196f3;
    }
  }
}

.show > .btn-info.dropdown-toggle {
  color: #fff !important;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-info {
  &.disabled, &.btn[disabled], &:disabled {
    background-color: #2196f3;
    border-color: #2196f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  &.active {
    &.focus, &:focus, &:hover {
      color: #fff !important;
      background-color: #2196f3;
      border-color: #2196f3;
    }
  }

  &.focus:active {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3;
  }

  &:active {
    &:focus, &:hover {
      color: #fff !important;
      background-color: #2196f3;
      border-color: #2196f3;
    }
  }
}

.open > .dropdown-toggle.btn-info {
  &.focus, &:focus, &:hover {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3;
  }
}

.btn-info .caret {
  border-top-color: #fff;
}

.btn-group.open .btn-info.dropdown-toggle {
  background-color: #bae7ff;
}

.btn-warning {
  color: #fff !important;
  background-color: #e2a03f;
  border-color: #e2a03f;

  &:hover, &:focus {
    color: #fff !important;
    background-color: #e2a03f;
    box-shadow: none;
    border-color: #e2a03f;
  }

  &:active, &.active {
    background-color: #e2a03f;
    border-top: 1px solid #e2a03f;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      color: #0e1726;
      background-color: #e2a03f;
      border-color: #e2a03f;
    }
  }
}

.show > .btn-warning.dropdown-toggle {
  color: #0e1726;
  background-color: #e2a03f;
  border-color: #e2a03f;
}

.btn-warning {
  &.disabled, &.btn[disabled], &:disabled {
    background-color: #e2a03f;
    border-color: #e2a03f;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  &.active {
    &.focus, &:focus, &:hover {
      color: #fff !important;
      background-color: #e2a03f;
      border-color: #e2a03f;
    }
  }

  &.focus:active {
    color: #fff !important;
    background-color: #e2a03f;
    border-color: #e2a03f;
  }

  &:active {
    &:focus, &:hover {
      color: #fff !important;
      background-color: #e2a03f;
      border-color: #e2a03f;
    }
  }
}

.open > .dropdown-toggle.btn-warning {
  &.focus, &:focus, &:hover {
    color: #fff !important;
    background-color: #e2a03f;
    border-color: #e2a03f;
  }
}

.btn-warning .caret {
  border-top-color: #fff;
}

.btn-group.open .btn-warning.dropdown-toggle {
  background-color: #df8505;
}

.btn-danger {
  color: #fff !important;
  background-color: #e7515a;
  border-color: #e7515a;

  &:hover, &:focus {
    color: #fff !important;
    background-color: #e7515a;
    box-shadow: none;
    border-color: #e7515a;
  }

  &:active, &.active {
    background-color: #e7515a;
    border-top: 1px solid #e7515a;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      color: #fff !important;
      background-color: #e7515a;
      border-color: #e7515a;
    }
  }
}

.show > .btn-danger.dropdown-toggle {
  color: #fff !important;
  background-color: #e7515a;
  border-color: #e7515a;
}

.btn-danger {
  &.disabled, &.btn[disabled], &:disabled {
    background-color: #e7515a;
    border-color: #e7515a;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  &.active {
    &.focus, &:focus, &:hover {
      color: #fff !important;
      background-color: #c00;
      border-color: #c00;
    }
  }

  &.focus:active {
    color: #fff !important;
    background-color: #c00;
    border-color: #c00;
  }

  &:active {
    &:focus, &:hover {
      color: #fff !important;
      background-color: #c00;
      border-color: #c00;
    }
  }
}

.open > .dropdown-toggle.btn-danger {
  &.focus, &:focus, &:hover {
    color: #fff !important;
    background-color: #c00;
    border-color: #c00;
  }
}

.btn-danger .caret {
  border-top-color: #fff;
}

.btn-group.open .btn-danger.dropdown-toggle {
  background-color: #a9302a;
}

.btn-dark {
  color: #fff !important;
  background-color: #3b3f5c;
  border-color: #3b3f5c;

  &:hover, &:focus {
    color: #fff !important;
    background-color: #3b3f5c;
    box-shadow: none;
    border-color: #3b3f5c;
  }

  &:active, &.active {
    background-color: #3b3f5c;
    border-top: 1px solid #3b3f5c;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      color: #fff !important;
      background-color: #3b3f5c;
      border-color: #3b3f5c;
    }
  }
}

.show > .btn-dark.dropdown-toggle {
  color: #fff !important;
  background-color: #3b3f5c;
  border-color: #3b3f5c;
}

.btn-dark {
  &.disabled, &.btn[disabled], &:disabled {
    background-color: #3b3f5c;
    border-color: #3b3f5c;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .caret {
    border-top-color: #fff;
  }
}

.btn-group.open .btn-dark.dropdown-toggle {
  background-color: #484848;
}

.btn-success {
  color: #fff !important;
  background-color: #8dbf42;
  border-color: #8dbf42;

  &:hover, &:focus {
    color: #fff !important;
    background-color: #8dbf42;
    box-shadow: none;
    border-color: #8dbf42;
  }

  &:active, &.active {
    background-color: #8dbf42;
    border-top: 1px solid #8dbf42;
  }

  &:not(:disabled):not(.disabled) {
    &.active, &:active {
      color: #fff !important;
      background-color: #8dbf42;
      border-color: #8dbf42;
    }
  }
}

.show > .btn-success.dropdown-toggle {
  color: #fff !important;
  background-color: #8dbf42;
  border-color: #8dbf42;
}

.btn-success {
  &.disabled, &.btn[disabled], &:disabled {
    background-color: #8dbf42;
    border-color: #8dbf42;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  &.active {
    &.focus, &:focus, &:hover {
      color: #fff !important;
      background-color: #17c678;
      border-color: #17c678;
    }
  }

  &.focus:active {
    color: #fff !important;
    background-color: #17c678;
    border-color: #17c678;
  }

  &:active {
    &:focus, &:hover {
      color: #fff !important;
      background-color: #17c678;
      border-color: #17c678;
    }
  }
}

.open > .dropdown-toggle.btn-success {
  &.focus, &:focus, &:hover {
    color: #fff !important;
    background-color: #17c678;
    border-color: #17c678;
  }
}

.btn-success .caret {
  border-top-color: #fff;
}

/*-----/Button Light Colors------*/

.btn.box-shadow-none {
  border: none;

  &:hover, &:focus {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
  }
}

.box-shadow-none {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.btn.box-shadow-none:not(:disabled):not(.disabled) {
  &.active, &:active {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
  }
}

.show > .btn.box-shadow-none.dropdown-toggle {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

.btn-group.open .btn-success.dropdown-toggle {
  background-color: #499249;
}

.btn-dismiss {
  color: #0e1726;
  background-color: #fff !important;
  border-color: #fff;
  padding: 3px 7px;

  &:hover, &:focus {
    color: #0e1726;
    background-color: #fff;
  }

  &:active, &.active {
    background-color: #fff;
    border-top: 1px solid #fff;
  }
}

.btn-group {
  > .btn {
    i {
      margin-right: 3px;
    }

    &:first-child:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
      -moz-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
    }

    + .dropdown-toggle {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      &.btn-primary {
        border-left: 1px solid rgb(68, 104, 253);
      }

      &.btn-success {
        border-left: 1px solid rgb(163, 198, 111);
      }

      &.btn-info {
        border-left: 1px solid rgb(73, 172, 251);
      }

      &.btn-warning {
        border-left: 1px solid rgb(245, 180, 85);
      }

      &.btn-danger {
        border-left: 1px solid rgb(241, 132, 139);
      }

      &.btn-dark {
        border-left: 1px solid rgb(112, 118, 122);
      }

      &.btn-secondary {
        border-left: 1px solid rgb(131, 83, 220);
      }
    }
  }

  &.dropleft {
    .dropdown-toggle-split {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .btn-primary:not(.dropdown-toggle-split) {
      border-left: 1px solid rgb(68, 104, 253);
    }

    .btn-success:not(.dropdown-toggle-split) {
      border-left: 1px solid rgb(163, 198, 111);
    }

    .btn-info:not(.dropdown-toggle-split) {
      border-left: 1px solid rgb(73, 172, 251);
    }

    .btn-warning:not(.dropdown-toggle-split) {
      border-left: 1px solid rgb(245, 180, 85);
    }

    .btn-danger:not(.dropdown-toggle-split) {
      border-left: 1px solid rgb(241, 132, 139);
    }

    .btn-dark:not(.dropdown-toggle-split) {
      border-left: 1px solid rgb(112, 118, 122);
    }

    .btn-secondary:not(.dropdown-toggle-split) {
      border-left: 1px solid rgb(131, 83, 220);
    }
  }
}

/*
    Btn group dropdown-toggle
*/

.btn .badge.badge-align-right {
  position: absolute;
  top: -1px;
  right: 8px;
}

.dropup .btn .caret {
  border-bottom-color: #0e1726;
}

.btn-outline-primary:not(:disabled):not(.disabled) {
  &.active, &:active {
    background-color: #1b55e2;
    color: #fff !important;
  }
}

.show > .btn-outline-primary.dropdown-toggle {
  background-color: #1b55e2;
  color: #fff !important;
}

.btn-outline-success:not(:disabled):not(.disabled) {
  &.active, &:active {
    background-color: #8dbf42;
    color: #fff !important;
  }
}

.show > .btn-outline-success.dropdown-toggle {
  background-color: #8dbf42;
  color: #fff !important;
}

.btn-outline-info:not(:disabled):not(.disabled) {
  &.active, &:active {
    background-color: #2196f3;
    color: #fff !important;
  }
}

.show > .btn-outline-info.dropdown-toggle {
  background-color: #2196f3;
  color: #fff !important;
}

.btn-outline-danger:not(:disabled):not(.disabled) {
  &.active, &:active {
    background-color: #e7515a;
    color: #fff !important;
  }
}

.show > .btn-outline-danger.dropdown-toggle {
  background-color: #e7515a;
  color: #fff !important;
}

.btn-outline-warning:not(:disabled):not(.disabled) {
  &.active, &:active {
    background-color: #e2a03f;
    color: #fff !important;
  }
}

.show > .btn-outline-warning.dropdown-toggle {
  background-color: #e2a03f;
  color: #fff !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled) {
  &.active, &:active {
    background-color: #5c1ac3;
    color: #fff !important;
  }
}

.show > .btn-outline-secondary.dropdown-toggle {
  background-color: #5c1ac3;
  color: #fff !important;
}

.btn-outline-dark:not(:disabled):not(.disabled) {
  &.active, &:active {
    background-color: #3b3f5c;
    color: #fff !important;
  }
}

.show > {
  .btn-outline-dark.dropdown-toggle {
    background-color: #3b3f5c;
    color: #fff !important;
  }

  .btn-outline-primary.dropdown-toggle:after, .btn-outline-success.dropdown-toggle:after, .btn-outline-info.dropdown-toggle:after, .btn-outline-danger.dropdown-toggle:after, .btn-outline-warning.dropdown-toggle:after, .btn-outline-secondary.dropdown-toggle:after, .btn-outline-dark.dropdown-toggle:after, .btn-outline-primary.dropdown-toggle:before, .btn-outline-success.dropdown-toggle:before, .btn-outline-info.dropdown-toggle:before, .btn-outline-danger.dropdown-toggle:before, .btn-outline-warning.dropdown-toggle:before, .btn-outline-secondary.dropdown-toggle:before, .btn-outline-dark.dropdown-toggle:before {
    color: #fff !important;
  }
}

.btn-outline-primary {
  border: 1px solid #1b55e2 !important;
  color: #1b55e2 !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-info {
  border: 1px solid #2196f3 !important;
  color: #2196f3 !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-warning {
  border: 1px solid #e2a03f !important;
  color: #e2a03f !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-success {
  border: 1px solid #8dbf42 !important;
  color: #8dbf42 !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-danger {
  border: 1px solid #e7515a !important;
  color: #e7515a !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-secondary {
  border: 1px solid #5c1ac3 !important;
  color: #5c1ac3 !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-dark {
  border: 1px solid #3b3f5c !important;
  color: #3b3f5c !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-primary:hover, .btn-outline-info:hover, .btn-outline-warning:hover, .btn-outline-success:hover, .btn-outline-danger:hover, .btn-outline-secondary:hover, .btn-outline-dark:hover {
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #1b55e2;
}

.btn-outline-info:hover {
  color: #fff !important;
  background-color: #2196f3;
}

.btn-outline-warning:hover {
  color: #fff !important;
  background-color: #e2a03f;
}

.btn-outline-success:hover {
  color: #fff !important;
  background-color: #8dbf42;
}

.btn-outline-danger:hover {
  color: #fff !important;
  background-color: #e7515a;
}

.btn-outline-secondary:hover {
  color: #fff !important;
  background-color: #5c1ac3;
}

.btn-outline-dark:hover {
  color: #fff !important;
  background-color: #3b3f5c;
}

/*      Dropdown Toggle       */

.btn-rounded {
  -webkit-border-radius: 1.875rem !important;
  -moz-border-radius: 1.875rem !important;
  -ms-border-radius: 1.875rem !important;
  -o-border-radius: 1.875rem !important;
  border-radius: 1.875rem !important;
}

/*
    ===========================
        Data Marker ( dot )
    ===========================
*/

.data-marker {
  padding: 2px;
  border-radius: 50%;
  font-size: 18px;
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.data-marker-success {
  background-color: #8dbf42;
}

.data-marker-warning {
  background-color: #e2a03f;
}

.data-marker-danger, .data-marker-info, .data-marker-dark {
  background-color: #e7515a;
}

.badge {
  font-weight: 600;
  line-height: 1.4;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 600;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;

  &:hover {
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }

  &.badge-enabled {
    background-color: #8dbf42;
    color: #fff;
  }

  &.badge-disable {
    background-color: #e7515a;
    color: #fff;
  }
}

.badge-pills {
  border-radius: 30px;
}

.badge-classic {
  border-radius: 0;
}

.badge-collapsed-img {
  img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.3);
    margin-left: -21px;
  }

  &.badge-tooltip img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.3);
    margin-left: -21px;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;

    &:hover {
      -webkit-transform: translateY(-5px) scale(1.02);
      transform: translateY(-5px) scale(1.02);
    }
  }

  &.translateY-axis img {
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;

    &:hover {
      -webkit-transform: translateY(-5px) scale(1.02);
      transform: translateY(-5px) scale(1.02);
    }
  }

  &.rectangle-collapsed img {
    width: 45px;
    height: 32px;
  }

  &.translateX-axis img {
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;

    &:hover {
      -webkit-transform: translateX(5px) scale(1.02);
      transform: translateX(5px) scale(1.02);
    }
  }
}

.badge-primary {
  color: #fff;
  background-color: #1b55e2;
}

.badge-info {
  color: #fff;
  background-color: #2196f3;
}

.badge-success {
  color: #fff;
  background-color: #8dbf42;
}

.badge-danger {
  color: #fff;
  background-color: #e7515a;
}

.badge-warning {
  color: #fff;
  background-color: #e2a03f;
}

.badge-dark {
  color: #fff;
  background-color: #3b3f5c;
}

.badge-secondary {
  background-color: #5c1ac3;
}

.outline-badge-primary {
  color: #1b55e2;
  background-color: transparent;
  border: 1px solid #1b55e2;
}

.outline-badge-info {
  color: #2196f3;
  background-color: transparent;
  border: 1px solid #2196f3;
}

.outline-badge-success {
  color: #8dbf42;
  background-color: transparent;
  border: 1px solid #8dbf42;
}

.outline-badge-danger {
  color: #e7515a;
  background-color: transparent;
  border: 1px solid #e7515a;
}

.outline-badge-warning {
  color: #e2a03f;
  background-color: transparent;
  border: 1px solid #e2a03f;
}

.outline-badge-dark {
  color: #3b3f5c;
  background-color: transparent;
  border: 1px solid #3b3f5c;
}

.outline-badge-secondary {
  color: #5c1ac3;
  background-color: transparent;
  border: 1px solid #5c1ac3;
}

.outline-badge-primary {
  &:focus, &:hover {
    background-color: #c2d5ff;
    color: #1b55e2;
  }
}

.outline-badge-secondary {
  &:focus, &:hover {
    color: #5c1ac3;
    background-color: #dccff7;
  }
}

.outline-badge-success {
  &:focus, &:hover {
    color: #8dbf42;
    background-color: #e6ffbf;
  }
}

.outline-badge-danger {
  &:focus, &:hover {
    color: #e7515a;
    background-color: #ffe1e2;
  }
}

.outline-badge-warning {
  &:focus, &:hover {
    color: #e2a03f;
    background-color: #ffeccb;
  }
}

.outline-badge-info {
  &:focus, &:hover {
    color: #2196f3;
    background-color: #bae7ff;
  }
}

.outline-badge-dark {
  &:focus, &:hover {
    color: #3b3f5c;
    background-color: #acb0c3;
  }
}

/*      Link     */

.badge[class*="link-badge-"] {
  cursor: pointer;
}

.link-badge-primary {
  color: #1b55e2;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-info {
  color: #2196f3;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-success {
  color: #8dbf42;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-danger {
  color: #e7515a;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-warning {
  color: #e2a03f;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-dark {
  color: #3b3f5c;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-secondary {
  color: #5c1ac3;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-primary {
  &:focus, &:hover {
    color: #1b55e2;
    background-color: transparent;
  }
}

.link-badge-secondary {
  &:focus, &:hover {
    color: #6f51ea;
    background-color: transparent;
  }
}

.link-badge-success {
  &:focus, &:hover {
    color: #2ea37d;
    background-color: transparent;
  }
}

.link-badge-danger {
  &:focus, &:hover {
    color: #e7515a;
    background-color: transparent;
  }
}

.link-badge-warning {
  &:focus, &:hover {
    color: #dea82a;
    background-color: transparent;
  }
}

.link-badge-info {
  &:focus, &:hover {
    color: #009eda;
    background-color: transparent;
  }
}

.link-badge-dark {
  &:focus, &:hover {
    color: #454656;
    background-color: transparent;
  }
}

/* Custom Dropdown*/

.custom-dropdown .dropdown-toggle::after, .custom-dropdown-icon .dropdown-toggle::after, .custom-dropdown .dropdown-toggle::before, .custom-dropdown-icon .dropdown-toggle::before {
  display: none;
}

.custom-dropdown .dropdown-menu, .custom-dropdown-icon .dropdown-menu {
  min-width: 11rem;
  border-radius: 4px;
  border: none;
  border: 1px solid #e0e6ed;
  z-index: 899;
  box-shadow: rgba(113, 106, 202, 0.2) 0px 0px 15px 1px;
  top: 15px !important;
  padding: 10px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}

.custom-dropdown .dropdown-item {
  &.active, &:active, &:hover {
    color: #888ea8;
    background-color: #f1f2f3;
  }
}

.custom-dropdown-icon .dropdown-item {
  &.active, &:active, &:hover {
    color: #888ea8;
    background-color: #f1f2f3;
  }
}

.custom-dropdown .dropdown-item {
  font-size: 13px;
  color: #888ea8;
  display: block;
  font-weight: 700;
  padding: 11px 8px;
  font-size: 12px;
}

.custom-dropdown-icon {
  .dropdown-item {
    font-size: 13px;
    color: #888ea8;
    display: block;
    font-weight: 700;
    padding: 11px 8px;
    font-size: 12px;
  }

  .dropdown-menu .dropdown-item svg {
    width: 20px;
    height: 20px;
    margin-right: 3px;
    color: #888ea8;
  }
}

.custom-dropdown .dropdown-item {
  &.active svg, &:active svg, &:hover svg {
    color: #1b55e2;
  }
}

.custom-dropdown-icon .dropdown-item {
  &.active svg, &:active svg, &:hover svg {
    color: #1b55e2;
  }
}

.status.rounded-tooltip .tooltip-inner {
  border-radius: 20px;
  padding: 8px 20px;
}

.tooltip-inner {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.popover {
  z-index: 999;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-bottom-color: #b3b3b3;
}

input[disabled], select[disabled], textarea[disabled], input[readonly], select[readonly], textarea[readonly] {
  cursor: not-allowed;
  background-color: #f1f2f3 !important;
  color: #acb0c3;
}

.help-block, .help-inline {
  color: #555555;
}

.controls {
  position: relative;
}

.search-form-control {
  border-radius: .25rem;
}

/*  Table   */

.table-bordered {
  border: 1px solid #f1f2f3;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f1f2f3 !important;
}

.table > {
  tbody > tr > td {
    vertical-align: middle;
    color: #515365;
    font-size: 13px;
    letter-spacing: 1px;
  }

  thead > tr > th {
    color: #1b55e2;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  tbody > tr > td {
    .usr-img-frame {
      background-color: #ebedf2;
      padding: 2px;
      width: 35px;
      height: 35px;

      img {
        width: 35px;
        margin: 0;
      }
    }

    .admin-name {
      font-weight: 700;
      color: #515365;
    }

    .progress {
      width: 135px;
      height: 6px;
      margin: auto 0;
    }

    svg.icon {
      width: 21px;
    }

    .t-dot {
      background-color: #000;
      height: 11px;
      width: 11px;
      border-radius: 50%;
      cursor: pointer;
      margin: 0 auto;
    }

    svg.t-icon {
      padding: 5px;
      border-radius: 50%;
      font-size: 11px;
      vertical-align: sub;
      cursor: pointer;

      &.t-hover-icon:hover {
        background-color: #e7515a;
        color: #fff;
      }
    }
  }
}

.table-bordered {
  td, th {
    border: 1px solid #ebedf2;
  }
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none;
}

.table-hover:not(.table-dark) tbody tr:hover {
  background-color: #f1f2f3 !important;
}

.table-controls > li > a svg {
  color: #25d5e4;
}

.table tr td {
  .custom-dropdown.t-custom-dropdown a.dropdown-toggle, .custom-dropdown-icon.t-custom-dropdown a.dropdown-toggle {
    border-radius: 5px;
    border: 1px solid #d3d3d3;
  }
}

.table-controls > li > a svg {
  color: #888ea8;
  width: 21px;
}

/*  Table Dark      */

.table.table-dark > {
  thead > tr > th {
    color: #d3d3d3;
  }

  tbody > tr > td {
    color: #ffffff;
  }
}

.table-dark {
  background-color: #060818;

  &.table-hover tbody tr {
    background-color: #060818;
  }

  td, th, thead th {
    border-color: #191e3a !important;
  }

  &.table-hover tbody tr:hover {
    background-color: rgba(25, 30, 58, 0.631372549);
  }
}

.table.table-dark > tbody > tr > td i.t-icon {
  padding: 5px;
  border-radius: 50%;
  font-size: 14px;
  vertical-align: sub;
  cursor: pointer;
  color: #0e1726 !important;
}

table {
  .badge-success, .badge-primary, .badge-warning, .badge-danger, .badge-info, .badge-secondary, .badge-dark {
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.2);
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
  }
}

.table > tfoot > tr > th {
  color: #3b3f5c;
}

.table-vertical-align {
  tr, th, td {
    vertical-align: middle !important;
  }
}

.statbox .widget-content {
  &:before, &:after {
    display: table;
    content: "";
    line-height: 0;
    clear: both;
  }
}

.nav-tabs > li > a {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.btn-toolbar {
  margin-left: 0px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .input-group > .form-control {
    flex: 1 1 auto;
    width: 1%;
  }
}

.spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.toast-primary {
  background: #1b55e2;
}

.toast-header {
  background: #1b55e2;
  color: #fff;
  border-bottom: 1px solid rgba(33, 150, 243, 0.3411764706);

  .meta-time {
    color: #f1f2f3;
  }

  .close {
    color: #f1f2f3;
    opacity: 1;
    text-shadow: none;
  }
}

.toast-body {
  padding: 16px 12px;
  color: #fff;
}

/*  
    ==========================
        Background Colors  
    ==========================
*/

/*  
    Default  
*/

.bg-primary {
  background-color: #1b55e2 !important;
  border-color: #1b55e2;
  color: #fff;
}

.bg-success {
  background-color: #8dbf42 !important;
  border-color: #8dbf42;
  color: #fff;
}

.bg-info {
  background-color: #2196f3 !important;
  border-color: #2196f3;
  color: #fff;
}

.bg-warning {
  background-color: #e2a03f !important;
  border-color: #e2a03f;
  color: #fff;
}

.bg-danger {
  background-color: #e7515a !important;
  border-color: #e7515a;
  color: #fff;
}

.bg-secondary {
  background-color: #5c1ac3 !important;
  border-color: #5c1ac3;
  color: #fff;
}

.bg-dark {
  background-color: #fff;
  border-color: #3b3f5c;
  color: #fff;
}

/*  
    Light Background  
*/

.bg-light-primary {
  background-color: #c2d5ff !important;
  border-color: #c2d5ff;
  color: #2196f3;
}

.bg-light-success {
  background-color: #e6ffbf !important;
  border-color: #e6ffbf;
  color: #8dbf42;
}

.bg-light-info {
  background-color: #bae7ff !important;
  border-color: #bae7ff;
  color: #2196f3;
}

.bg-light-warning {
  background-color: #ffeccb !important;
  border-color: #ffeccb;
  color: #e2a03f;
}

.bg-light-danger {
  background-color: #ffe1e2 !important;
  border-color: #ffe1e2;
  color: #e7515a;
}

.bg-light-secondary {
  background-color: #dccff7 !important;
  border-color: #dccff7;
  color: #5c1ac3;
}

.bg-light-dark {
  background-color: #acb0c3;
  border-color: #acb0c3;
  color: #fff;
}

/*  
    Progress Bar
*/

.progress {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-color: #ebedf2;
  margin-bottom: 1.25rem;
  height: 16px;
  box-shadow: 1px 3px 20px 3px #f1f2f3;

  &.progress-bar-stack .progress-bar:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .progress-bar {
    font-size: 10px;
    font-weight: 700;
    box-shadow: 0 2px 4px rgba(0, 69, 255, 0.15), 0 8px 16px rgba(0, 69, 255, 0.2);
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 100;
  }

  &:not(.progress-bar-stack) .progress-bar {
    border-radius: 16px;
  }
}

.progress-sm {
  height: 4px;
}

.progress-md {
  height: 10px;
}

.progress-lg {
  height: 20px;
}

.progress-xl {
  height: 25px;
}

.progress-striped .progress-bar {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress {
  .progress-title {
    display: flex;
    justify-content: space-between;
    padding: 15px;

    span {
      align-self: center;
    }
  }

  .progress-bar {
    &.bg-gradient-primary {
      background-color: #1b55e2;
      background: linear-gradient(to right, #0081ff 0%, #0045ff 100%);
    }

    &.bg-gradient-info {
      background-color: #1b55e2;
      background-image: linear-gradient(to right, #04befe 0%, #4481eb 100%);
    }

    &.bg-gradient-success {
      background-color: #1b55e2;
      background-image: linear-gradient(to right, #3cba92 0%, #0ba360 100%);
    }

    &.bg-gradient-warning {
      background-color: #1b55e2;
      background-image: linear-gradient(to right, #f09819 0%, #ff5858 100%);
    }

    &.bg-gradient-secondary {
      background-color: #1b55e2;
      background-image: linear-gradient(to right, #7579ff 0%, #b224ef 100%);
    }

    &.bg-gradient-danger {
      background-color: #1b55e2;
      background-image: linear-gradient(to right, #d09693 0%, #c71d6f 100%);
    }

    &.bg-gradient-dark {
      background-color: #1b55e2;
      background-image: linear-gradient(to right, #2b5876 0%, #4e4376 100%);
    }
  }
}

.br-0 {
  border-radius: 0 !important;
}

.br-4 {
  border-radius: 4px !important;
}

.br-6 {
  border-radius: 6px !important;
}

.br-30 {
  border-radius: 30px !important;
}

.br-50 {
  border-radius: 50px !important;
}

.br-left-30 {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.br-right-30 {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.bx-top-6 {
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
}

.bx-bottom-6 {
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

/*      Badge Custom      */

.badge.counter {
  position: absolute;
  z-index: 2;
  right: 0;
  top: -10px;
  font-weight: 600;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  padding: 2px 0px;
  font-size: 12px;
}

.badge-chip {
  display: inline-block;
  padding: 0 25px;
  font-size: 16px;
  line-height: 42px;
  border-radius: 25px;

  img {
    float: left;
    margin: 0px 10px 0px -26px;
    height: 44px;
    width: 44px;
    border-radius: 50%;
  }

  .closebtn {
    color: #f1f2f3;
    font-weight: bold;
    float: right;
    font-size: 20px;
    cursor: pointer;

    &:hover {
      color: #fff;
    }
  }
}

/*-------text-colors------*/

.text-primary {
  color: #1b55e2 !important;
}

.text-success {
  color: #8dbf42 !important;
}

.text-info {
  color: #2196f3 !important;
}

.text-danger {
  color: #e7515a !important;
}

.text-warning {
  color: #e2a03f !important;
}

.text-secondary {
  color: #5c1ac3 !important;
}

.text-dark {
  color: #3b3f5c !important;
}

.text-muted {
  color: #888ea8 !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

/*-----border main------*/

.border {
  border: 1px solid !important;
}

.border-bottom {
  border-bottom: 1px solid !important;
}

.border-top {
  border-top: 1px solid !important;
}

.border-right {
  border-right: 1px solid !important;
}

.border-left {
  border-left: 1px solid !important;
}

.border-primary {
  border-color: #1b55e2 !important;
}

.border-info {
  border-color: #2196f3 !important;
}

.border-warning {
  border-color: #e2a03f !important;
}

.border-success {
  border-color: #8dbf42 !important;
}

.border-danger {
  border-color: #e7515a !important;
}

.border-secondary {
  border-color: #5c1ac3 !important;
}

.border-dark {
  border-color: #3b3f5c !important;
}

/*-----border style------*/

.border-dotted {
  border-style: dotted !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-solid {
  border-style: solid !important;
}

.border-double {
  border-style: double !important;
}

/*-----border width------*/

.border-width-1px {
  border-width: 1px !important;
}

.border-width-2px {
  border-width: 2px !important;
}

.border-width-3px {
  border-width: 3px !important;
}

.border-width-4px {
  border-width: 4px !important;
}

.border-width-5px {
  border-width: 5px !important;
}

.border-width-6px {
  border-width: 6px !important;
}

/*-----transform-position------*/

.position-absolute {
  position: absolute;
}

.position-static {
  position: static;
}

.position-fixed {
  position: fixed;
}

.position-inherit {
  position: inherit;
}

.position-initial {
  position: initial;
}

.position-relative {
  position: relative;
}